import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {getShopifyData} from "../../lib/dynamicQueries/config";
import {getEstimateCost, getEstimatedCostForPhysicalItems, updateCart} from "../cart";

const GET_GIFT_TIERS_QUERY = `query GetGiftTiers {
  metaobjects(type: "gift_tier", first: 50) {
    edges {
      node {
        id
        handle
        fields {
          key
          value
          reference {
            ... on Product {
              id
              title
              handle
              priceRange {
                minVariantPrice {
                  amount
                }
              }
              featuredImage {
                url
                altText
              }
            }
            ... on ProductVariant {
              id
              product {
                id
                title
                handle
                featuredImage {
                  url
                  altText
                }
                priceRange {
                  minVariantPrice {
                    amount
                  }
                }
              }
              title
              sku
              selectedOptions {
                name
                value
              }
              price {
                amount
                currencyCode
              }
            }
          }
        }
      }
    }
  }
}`;

export const fetchGiftTiers = createAsyncThunk(
    'giftTiers/fetchGiftTiers',
    async (_, { rejectWithValue }) => {
      try {
        const response = await getShopifyData(GET_GIFT_TIERS_QUERY);

        return response.data.metaobjects.edges.map(edge => {
          const fields = edge.node.fields.reduce((acc, field) => {
            acc[field.key] = field.reference || field.value;
            return acc;
          }, {});

          return {
            id: edge.node.id,
            handle: edge.node.handle,
            thresholdAmount: parseFloat(fields.threshold_amount),
            giftProduct: fields.gift_variant ? fields.gift_variant.product : fields.giftProduct,
            giftVariant: fields.gift_variant,
            tierName: fields.tier_name,
            tierDescription: fields.description,
            achievedText: fields.achieved_text,
          };
        });
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
);

const giftTiersSlice = createSlice({
  name: 'giftTiers',
  initialState: {
    tiers: [],
    loading: false,
    error: null,
    currentQualifiedTier: null,
    qualifiedTiers: [],
    nextTier: null,
    remainingAmount: 0,
    percentageToNextTier: 0
  },
  reducers: {
    updateGiftTierStatus: (state, action) => {
      const cartTotal = action.payload;
      const sortedTiers = [...state.tiers].sort((a, b) => a.thresholdAmount - b.thresholdAmount);

      // Find current qualified tier
      state.currentQualifiedTier = sortedTiers
          .filter(tier => cartTotal >= tier.thresholdAmount)
          .pop() || null;

      state.qualifiedTiers = sortedTiers
          .filter(tier => cartTotal >= tier.thresholdAmount);

      // Find next tier
      state.nextTier = sortedTiers
          .find(tier => cartTotal < tier.thresholdAmount) || null;

      // Calculate remaining amount and percentage
      if (state.nextTier) {
        state.remainingAmount = state.nextTier.thresholdAmount - cartTotal;
        const rangeToNextTier = state.nextTier.thresholdAmount -
            (state.currentQualifiedTier?.thresholdAmount || 0);
        const progressInRange = cartTotal -
            (state.currentQualifiedTier?.thresholdAmount || 0);
        state.percentageToNextTier = Math.min(
            (progressInRange / rangeToNextTier) * 100,
            100
        );
      } else {
        state.remainingAmount = 0;
        state.percentageToNextTier = 100;
      }
    }
  },
  extraReducers: (builder) => {
    builder
        .addCase(fetchGiftTiers.pending, (state) => {
          state.loading = true;
        })
        .addCase(fetchGiftTiers.fulfilled, (state, action) => {
          state.loading = false;
          state.tiers = action.payload;
        })
        .addCase(fetchGiftTiers.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        })
        .addCase(updateCart, (state, action) => {
          const productBundles = action.payload.productBundles ? JSON.parse(action.payload.productBundles.value) : [];
          const cartTotal = getEstimatedCostForPhysicalItems(action.payload.lines.nodes, productBundles);
          const sortedTiers = [...state.tiers].sort((a, b) =>
              a.thresholdAmount - b.thresholdAmount
          );

          state.currentQualifiedTier = sortedTiers
              .filter(tier => cartTotal >= tier.thresholdAmount)
              .pop() || null;

          // Find next tier
          state.nextTier = sortedTiers
              .find(tier => cartTotal < tier.thresholdAmount) || null;

          // Calculate remaining amount and percentage
          if (state.nextTier) {
            state.remainingAmount = state.nextTier.thresholdAmount - cartTotal;
            const rangeToNextTier = state.nextTier.thresholdAmount -
                (state.currentQualifiedTier?.thresholdAmount || 0);
            const progressInRange = cartTotal -
                (state.currentQualifiedTier?.thresholdAmount || 0);
            state.percentageToNextTier = Math.min(
                (progressInRange / rangeToNextTier) * 100,
                100
            );
          } else {
            state.remainingAmount = 0;
            state.percentageToNextTier = 100;
          }
        });
  }
});

export const { updateGiftTierStatus } = giftTiersSlice.actions;
export default giftTiersSlice.reducer;
