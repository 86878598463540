import { getShopifyData } from "../config";

export const GetCart = async (id) => {
  const COUNTRY_CODE = process.env.GATSBY_LOCALE_COUNTRY;
  const CLUB_ACTIVE = process.env.GATSBY_CLUB_ACTIVE;

  try {
    const query = `query @inContext(country: ${COUNTRY_CODE}) {
      cart(id: "${id}") {
        id
        checkoutUrl
        freeGiftTier: metafield(namespace: "app--1433174017", key: "freeGift") {
          value
        }
        giftsWithPurchase: metafield(namespace: "app--1433174017", key: "giftsWithPurchase") {
          value
        }
        productBundles: metafield(namespace: "app--1433174017", key: "productBundles") {
          value
        }
        completed: metafield(namespace: "app--1433174017", key: "completed") {
            value
        }
        lines(first: 25) {
          nodes {
            id
            attribute(key: "DISCOUNT") {
              value
            }
            preorder: attribute(key: "preorder") {
              value
            }
            merchandise {
              ... on ProductVariant {
                id
                sku
                requiresShipping
                giftWithPurchase: metafield(namespace: "custom", key: "gift_with_purchase") {
                  value
                }
                clubPrice: metafield(namespace: "custom", key: "${CLUB_ACTIVE}") {
                  value
                }
                product {
                  id
                  title
                  handle
                  vendor
                  clubEnabled: metafield(namespace: "custom", key: "${CLUB_ACTIVE}_enabled") {
                    value
                  }
                  clubPrice: metafield(namespace: "custom", key: "${CLUB_ACTIVE}") {
                    value
                  }
                  clubNZ: metafield(namespace: "custom", key: "club_nz") {
                    value
                  }
                  clubAU: metafield(namespace: "custom", key: "club_au") {
                    value
                  }
                  clubUS: metafield(namespace: "custom", key: "club_us") {
                    value
                  }
                  clubUK: metafield(namespace: "custom", key: "club_uk") {
                    value
                  }
                  clubPT: metafield(namespace: "custom", key: "club_pt") {
                    value
                  }
                  metafield(namespace: "custom", key: "${CLUB_ACTIVE}") {
                    value
                  }
                  collections(first: 20) {
                    nodes {
                      id
                      title
                    }
                  }
                  variants(first: 250) {
                    nodes {
                      id
                      title
                      metafield(namespace: "custom", key: "${CLUB_ACTIVE}") {
                        value
                      }
                    }
                  }
                }
                selectedOptions {
                  name
                  value
                }
                availableForSale
                title
                image {
                  url
                  altText
                }
                price {
                  amount
                }
                compareAtPrice {
                  amount
                }
                weight
                weightUnit
              }
            }
            quantity
            discountAllocations {
              discountedAmount {
                amount
                currencyCode
              }
            }
          }
        }
        attributes {
          key
          value
        }
        discountCodes {
          code
          applicable
        }
        estimatedCost {
          totalAmount {
            amount
            currencyCode
          }
          subtotalAmount {
            amount
            currencyCode
          }
          totalTaxAmount {
            amount
            currencyCode
          }
          totalDutyAmount {
            amount
            currencyCode
          }
        }
      }
    }
    `;

    const response = await getShopifyData(query);
    return response?.data;
  } catch (error) {
    console.error(error);
  }
};
