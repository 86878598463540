export async function getDiscountPrice(productPrice, clubPrice, quantity) {
  const clubPricing = parseCurrency(clubPrice);
  const discountedValue = calculateDiscountedValue(
    clubPricing,
    productPrice,
    quantity
  );

  return discountedValue.toFixed(2);
}

export function parseCurrency(price) {
  const currencyPattern = /[\$£€¥₹]/g;
  const numericPrice = price.replace(currencyPattern, "").replace(/,/g, "");
  return parseFloat(numericPrice);
}

function calculateDiscountedValue(clubPrice, productPrice, quantity) {
  return Math.abs((clubPrice - parseFloat(productPrice)) * quantity);
}
