import { getShopifyData } from "../config";
import { GetCart } from "./Get";
import { updateCart } from "../../../slices/cart";
import { store } from "../../../store";
import {createEmptyCart} from "./Create";

const SET_CART_METAFIELDS_MUTATION = `
mutation cartMetafieldsSet($metafields: [CartMetafieldsSetInput!]!) {
  cartMetafieldsSet(metafields: $metafields) {
    metafields {
      id
      namespace
      key
      value
    }
  }
}`;

const REMOVE_CART_METAFIELDS_MUTATION = `
mutation cartMetafieldDelete($input: CartMetafieldDeleteInput!) {
  cartMetafieldDelete(input: $input) {
    deletedId
    userErrors {
      field
      message
    }
  }
}`;

export const setCartMetafields = async (metafields) => {
  try {
    const response = await getShopifyData({
      query: SET_CART_METAFIELDS_MUTATION,
      variables: { metafields },
    });

    if (
      response.data.cartMetafieldsSet.userErrors &&
      response.data.cartMetafieldsSet.userErrors.length > 0
    ) {
      console.error(
        "Error setting cart metafields:",
        response.data.cartMetafieldsSet.userErrors
      );
      return null;
    }

    return response.data.cartMetafieldsSet.metafields;
  } catch (error) {
    console.error("Error executing the mutation:", error);
    throw error;
  }
};
// Function to generate the metafields for a given line item
export const getGiftWithPurcahseMetafieldValue = (
  productId,
  quantity,
  freeGiftVariant, description
) => {
  const metafields = [];

  for (let i = 1; i <= quantity; i++) {
    metafields.push({
      parentProductId: productId,
      variant: freeGiftVariant,
            description
    });
  }

  return metafields;
};

export const addFreeGiftMetafields = async (currentGifts, newFreeGifts, cartId) => {
    const metafields = [...currentGifts, ...newFreeGifts];

  try {
    const response = await getShopifyData(SET_CART_METAFIELDS_MUTATION, {
      metafields: {
        key: "giftsWithPurchase",
        value: JSON.stringify(metafields),
        type: "json",
        ownerId: cartId,
      },
    });

    if (response.errors && response.errors.length > 0) {
      console.error("Error setting cart metafields:", response.errors);
      return null;
    }

    const cartResponse = await GetCart(cartId);
    if (cartResponse?.cart) {
      store.dispatch(updateCart(cartResponse.cart));
    }

    return response.data.cartMetafieldsSet.metafields;
  } catch (error) {
    console.error("Error executing the mutation:", error);
    throw error;
  }
};

export const setProductBundleMetafield = async (cartId, bundles) => {
    try {
        if (!cartId) {
            const cartResposne = await createEmptyCart();
            cartId = cartResposne?.cart?.id;
        }
        const response = await getShopifyData(SET_CART_METAFIELDS_MUTATION,
            {
                metafields: {
                    key: 'productBundles',
                    value: JSON.stringify(bundles),
                    type: "json",
                    ownerId: cartId,
                }
            }
        );

        if (response.errors && response.errors.length > 0) {
            console.error("Error setting cart metafields:", response.errors);
            return null;
        }

        const cartResponse = await GetCart(cartId);
        if (cartResponse?.cart) {
            store.dispatch(updateCart(cartResponse.cart));
        }

        return response.data.cartMetafieldsSet.metafields;
    } catch (error) {
        console.error("Error executing the mutation:", error);
        throw error;
    }
};

export const updateFreeGiftMetafields = async (cartId, metafields) => {
  try {
    const response = await getShopifyData(SET_CART_METAFIELDS_MUTATION, {
      metafields: {
        key: "giftsWithPurchase",
        value: JSON.stringify(metafields),
        type: "json",
        ownerId: cartId,
      },
    });

    if (response.errors && response.errors.length > 0) {
      console.error("Error setting cart metafields:", response.errors);
      return null;
    }

    const cartResponse = await GetCart(cartId);
    if (cartResponse?.cart) {
      store.dispatch(updateCart(cartResponse.cart));
    }

    return response.data.cartMetafieldsSet.metafields;
  } catch (error) {
    console.error("Error executing the mutation:", error);
    throw error;
  }
};

export async function setFreeGiftMetafield(cartId, freeGiftTier) {
  try {
    const response = await getShopifyData(SET_CART_METAFIELDS_MUTATION, {
      metafields: {
        key: "freeGift",
        value: JSON.stringify(freeGiftTier),
        type: "json",
        ownerId: cartId,
      },
    });

    if (response.errors && response.errors.length > 0) {
      console.error("Error setting cart metafields:", response.errors);
      return null;
    }

    return response.data.cartMetafieldsSet.metafields;
  } catch (error) {
    console.error("Error executing the mutation:", error);
    throw error;
  }
}

export async function removeFreeGiftMetafields(cartId) {
  try {
    const response = await getShopifyData(REMOVE_CART_METAFIELDS_MUTATION, {
      input: {
        key: "freeGift",
        ownerId: cartId,
      },
    });

    if (response.errors && response.errors.length > 0) {
      console.error("Error removing cart metafields:", response.errors);
      return null;
    }

    return response.data.cartMetafieldDelete.deletedId;
  } catch (error) {
    console.error("Error executing the mutation:", error);
    throw error;
  }
}
