import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../slices/auth";
import cartReducer from "../slices/cart";
import wishlistReducer from "../slices/wishlist";
import filterReducer from "../slices/filter";
import trackingMiddleware from "../tracking/TrackingMiddleware";
import giftTiers from "../slices/giftTiers";
import productGifts from "../slices/productGifts";
import {productGiftsMiddleware} from "../slices/productGifts/productGiftsMiddleware";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    cart: cartReducer,
    wishlist: wishlistReducer,
    filter: filterReducer,
    giftTiers: giftTiers,
    productGifts: productGifts,
  },
  middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(trackingMiddleware)
          .prepend(productGiftsMiddleware.middleware),
});
