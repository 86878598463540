import { getShopifyData } from "../config";
import { useDatalayer } from "../../../utils/useDatalayer";

const COUNTRY_CODE = process.env.GATSBY_LOCALE_COUNTRY;
const CLUB_ACTIVE = process.env.GATSBY_CLUB_ACTIVE;

export const CreateCart = async (product) => {
  try {
    const query = `mutation CreateCart($lines: [CartLineInput!]) @inContext(country: ${COUNTRY_CODE}) {
    cartCreate(input: {lines: $lines, buyerIdentity: {countryCode: ${COUNTRY_CODE}}}) {
      cart {
        id
        checkoutUrl
        freeGiftTier: metafield(namespace: "app--1433174017", key: "freeGift") {
          value
        }
        lines(first: 25) {
          nodes {
            id
            attribute(key: "DISCOUNT") {
              value
            }
            preorder: attribute(key: "preorder") {
              value
            }
            merchandise {
              ... on ProductVariant {
                id
                sku
                requiresShipping
                clubPrice: metafield(namespace: "custom", key: "${CLUB_ACTIVE}") {
                  value
                }
                product {
                  id
                  title
                  handle
                  vendor
                  metafield(namespace: "custom", key: "${CLUB_ACTIVE}") {
                    value
                  }
                  clubEnabled: metafield(namespace: "custom", key: "${CLUB_ACTIVE}_enabled") {
                    value
                  }
                  clubPrice: metafield(namespace: "custom", key: "${CLUB_ACTIVE}") {
                    value
                  }
                  clubNZ: metafield(namespace: "custom", key: "club_nz") {
                    value
                  }
                  clubAU: metafield(namespace: "custom", key: "club_au") {
                    value
                  }
                  clubUS: metafield(namespace: "custom", key: "club_us") {
                    value
                  }
                  clubUK: metafield(namespace: "custom", key: "club_uk") {
                    value
                  }
                  clubPT: metafield(namespace: "custom", key: "club_pt") {
                    value
                  }
                  collections(first: 20) {
                    nodes {
                      id
                      title
                    }
                  }
                  variants(first: 250) {
                    nodes {
                      id
                      title
                      metafield(namespace: "custom", key: "${CLUB_ACTIVE}") {
                        value
                      }
                    }
                  }
                }
                selectedOptions {
                  name
                  value
                }
                availableForSale
                title
                image {
                  url
                  altText
                }
                price {
                  amount
                }
                compareAtPrice {
                  amount
                }
                weight
                weightUnit
              }
            }
            quantity
            discountAllocations {
              discountedAmount {
                amount
                currencyCode
              }
            }
          }
        }
        attributes {
          key
          value
        }
        discountCodes {
          code
          applicable
        }
        estimatedCost {
          totalAmount {
            amount
            currencyCode
          }
          subtotalAmount {
            amount
            currencyCode
          }
          totalTaxAmount {
            amount
            currencyCode
          }
          totalDutyAmount {
            amount
            currencyCode
          }
        }
      }
      userErrors {
        field
        code
        message
      }
    }
  }  
  `;
    const lineToAdd = {
      merchandiseId: product.id,
      quantity: product.quantity,
    };

    if (product.preorder) {
      lineToAdd.attributes = [
        { key: "preorder", value: JSON.stringify(product.preorder) },
      ];
    }
    const response = await getShopifyData(query, {
      lines: [lineToAdd],
    });

    const createCartResponse = response.data.cartCreate;
    const item = createCartResponse.cart.lines.nodes.find((line) => {
      return line.merchandise.id === product.id;
    });
    if (item) {
      const { addToCart } = useDatalayer();
      addToCart(
        {
          ...item.merchandise.product,
          shopifyId: item.merchandise.product.id,
          collections: [],
        },
        {
          ...item.merchandise,
          shopifyId: item.merchandise.id,
        },
        product.quantity
      );
    }

    return createCartResponse;
  } catch (error) {
    console.error(error);
  }
};

export const CreateCartWithCustomer = async (product, customer) => {
  try {
    const query = `mutation CreateCartWithCustomer($lines: [CartLineInput!]) @inContext(country: ${COUNTRY_CODE}) {
         cartCreate(input: {lines: $lines, buyerIdentity: {customerAccessToken: "${customer.accessToken}", countryCode: ${COUNTRY_CODE}}}) {
           cart {
             id
             checkoutUrl
             freeGiftTier: metafield(namespace: "app--1433174017", key: "freeGift") {
               value
             }
             lines(first: 25) {
               nodes {
                 id
                 attribute(key: "DISCOUNT") {
                  value
                }
                preorder: attribute(key: "preorder") {
                  value
                }
                 merchandise {
                   ... on ProductVariant {
                     id
                     sku
                     giftWithPurchase: metafield(namespace: "custom", key: "gift_with_purchase") {
                       value
                     }
                     product {
                       id
                       title
                       handle
                       vendor
                       metafield(namespace: "custom", key: "${CLUB_ACTIVE}") {
                        value
                      }
                       clubNZ: metafield(namespace: "custom", key: "club_nz") {
                         value
                       }
                       clubAU: metafield(namespace: "custom", key: "club_au") {
                         value
                       }
                       clubUS: metafield(namespace: "custom", key: "club_us") {
                         value
                       }
                       clubUK: metafield(namespace: "custom", key: "club_uk") {
                         value
                       }
                       clubPT: metafield(namespace: "custom", key: "club_pt") {
                         value
                       }
                       variants(first: 250) {
                        nodes {
                          id
                          title
                          metafield(namespace: "custom", key: "${CLUB_ACTIVE}") {
                            value
                          }
                        }
                      }
                     }
                     selectedOptions {
                       name
                       value
                     }
                     availableForSale
                     title
                     image {
                       url
                       altText
                     }
                     price {
                       amount
                     }
                     compareAtPrice {
                       amount
                     }
                     weight
                     weightUnit
                   }
                 }
                 quantity
                 discountAllocations {
                   discountedAmount {
                     amount
                     currencyCode
                   }
                 }
               }
             }
             attributes {
               key
               value
             }
             discountCodes {
               code
               applicable
             }
             estimatedCost {
               totalAmount {
                 amount
                 currencyCode
               }
               subtotalAmount {
                 amount
                 currencyCode
               }
               totalTaxAmount {
                 amount
                 currencyCode
               }
               totalDutyAmount {
                 amount
                 currencyCode
               }
             }
           }
           userErrors {
             code
             field
             message
           }
         }
       }`;

    const lineToAdd = {
      merchandiseId: product.id,
      quantity: product.quantity,
    };

    if (product.preorder) {
      lineToAdd.attributes = [
        { key: "preorder", value: JSON.stringify(product.preorder) },
      ];
    }
    const response = await getShopifyData(query, {
      lines: [lineToAdd],
    });
    const createCartResponse = response.data.cartCreate;
    const item = createCartResponse.cart.lines.nodes.find((line) => {
      return line.merchandise.id === product.id;
    });
    if (item) {
      const { addToCart } = useDatalayer();
      addToCart(
        {
          ...item.merchandise.product,
          shopifyId: item.merchandise.product.id,
          collections: [],
        },
        {
          ...item.merchandise,
          shopifyId: item.merchandise.id,
        },
        product.quantity
      );
    }

    return createCartResponse;
  } catch (error) {
    console.error(error);
  }
};

export const createEmptyCart = async () => {
  try {
    const query = `mutation @inContext(country: ${COUNTRY_CODE}) {
    cartCreate(input: {buyerIdentity: {countryCode: ${COUNTRY_CODE}}}) {
      cart {
        id
        checkoutUrl
        lines(first: 25) {
          nodes {
            id
            attribute(key: "DISCOUNT") {
              value
            }
            quantity
          }
        }
        attributes {
          key
          value
        }
        discountCodes {
          code
          applicable
        }
        estimatedCost {
          totalAmount {
            amount
            currencyCode
          }
          subtotalAmount {
            amount
            currencyCode
          }
          totalTaxAmount {
            amount
            currencyCode
          }
          totalDutyAmount {
            amount
            currencyCode
          }
        }
      }
      userErrors {
        field
        code
        message
      }
    }
  }  
  `;
    const response = await getShopifyData(query);
    return response.data.cartCreate;
  } catch (error) {
    console.error(error);
  }
};
