import React, { createContext, useContext } from 'react';
import trackingManager from "./TrackingManager";
const currencyCode = process.env.GATSBY_LOCALE_CURRENCY;
const country = process.env.GATSBY_LOCALE_COUNTRY;

const TrackingContext = createContext();

export const TrackingProvider = ({ children }) => {
  const value = {
    trackViewProduct: (product, variant) => {
      trackingManager.trackEvent('Product viewed', {
        productId: product.shopifyId,
        variantId: variant.shopifyId,
        productName: product.title,
        variantName: variant.title,
        price: product.price,
        currency: currencyCode,
        country: country,
      });
    },
    trackAddToCart: (cartId, product) => {
      trackingManager.trackEvent('Added to cart', {
        cartId,
        ...product,
      });
    },
    trackRemoveFromCart: (cartId, product) => {
      trackingManager.trackEvent('Removed from cart', {
        cartId,
        ...product
      });
    },
    trackViewCart: (cartItems, totalValue) => {
      trackingManager.trackEvent('Cart viewed', {
        lineItems: cartItems,
        totalValue,
        currency: currencyCode,
        country: country,
      });
    },
    trackClickCheckout: (cartItems, totalValue) => {
      trackingManager.trackEvent('Checkout clicked', {
        lineItems: cartItems,
        totalValue,
        currency: currencyCode,
        country: country,
      });
    },
  };

  return <TrackingContext.Provider value={value}>{children}</TrackingContext.Provider>;
};

export const useTracking = () => {
  const context = useContext(TrackingContext);
  if (context === undefined) {
    throw new Error('useTracking must be used within a TrackingProvider');
  }
  return context;
};
