import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getShopifyData } from "../../lib/dynamicQueries/config";
import { updateCart } from "../cart";

const GET_PRODUCT_GIFTS_QUERY = `query GetProductGifts {
  metaobjects(type: "product_gift", first: 50) {
    edges {
      node {
        id
        handle
        fields {
          key
          value
          references(first: 20) {
            edges {
              node {
                ... on Product {
                  id
                  title
                  handle
                  priceRange {
                    minVariantPrice {
                      amount
                    }
                  }
                  featuredImage {
                    url
                    altText
                  }
                }
                ... on ProductVariant {
                  id
                  product {
                    id
                    title
                    handle
                    featuredImage {
                      url
                      altText
                    }
                    priceRange {
                      minVariantPrice {
                        amount
                      }
                    }
                  }
                  title
                  sku
                  selectedOptions {
                    name
                    value
                  }
                  price {
                    amount
                    currencyCode
                  }
                }
              }
            }
          }
          reference {
            ... on Product {
              id
              title
              handle
              priceRange {
                minVariantPrice {
                  amount
                }
              }
              featuredImage {
                url
                altText
              }
            }
            ... on ProductVariant {
              id
              product {
                id
                title
                handle
                featuredImage {
                  url
                  altText
                }
                priceRange {
                  minVariantPrice {
                    amount
                  }
                }
              }
              title
              sku
              selectedOptions {
                name
                value
              }
              price {
                amount
                currencyCode
              }
            }
          }
        }
      }
    }
  }
}`;

export const fetchProductGifts = createAsyncThunk(
    'productGifts/fetchProductGifts',
    async (_, { rejectWithValue }) => {
      try {
        const response = await getShopifyData(GET_PRODUCT_GIFTS_QUERY);

        return response.data.metaobjects.edges.map(edge => {
          const fields = edge.node.fields.reduce((acc, field) => {
            if (field.references && field.references.edges && field.references.edges.length > 0) {
              if (field.key === 'qualifying_products') {
                acc[field.key] = field.references.edges.map(edge => ({
                  id: edge.node.id,
                  title: edge.node.title || ''
                }));
              } else if (field.key === 'qualifying_variants') {
                acc[field.key] = field.references.edges.map(edge => ({
                  id: edge.node.id,
                  product: {
                    id: edge.node.product?.id || ''
                  }
                }));
              }
            }
            else if (field.reference) {
              if (field.key === 'gift_variant') {
                acc[field.key] = {
                  id: field.reference.id,
                  title: field.reference.title,
                  price: field.reference.price,
                  sku: field.reference.sku,
                  selectedOptions: field.reference.selectedOptions,
                  product: {
                    id: field.reference.product.id,
                    title: field.reference.product.title,
                    handle: field.reference.product.handle,
                    featuredImage: field.reference.product.featuredImage
                  }
                };
              } else {
                acc[field.key] = field.reference;
              }
            } else {
              acc[field.key] = field.value;
            }
            return acc;
          }, {});

          return {
            id: edge.node.id,
            handle: edge.node.handle,
            qualifyingProducts: fields.qualifying_products || [],
            qualifyingVariants: fields.qualifying_variants || [],
            giftVariant: fields.gift_variant,
            giftTitle: fields.gift_title,
            description: fields.description,
            achievedText: fields.achieved_text
          };
        });
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
);

const productGiftsSlice = createSlice({
  name: 'productGifts',
  initialState: {
    gifts: [],
    loading: false,
    error: null,
    qualifiedProductGifts: []
  },
  reducers: {
    updateProductGiftQualifications: (state, action) => {
      const { cartItems } = action.payload;

      state.qualifiedProductGifts = [];

      cartItems.forEach(item => {
        const productId = item.merchandise.product.id;
        const variantId = item.merchandise.id;
        const quantity = item.quantity;

        const matchingGifts = state.gifts.filter(gift => {
          const productMatch = gift.qualifyingProducts && gift.qualifyingProducts.some(
              qualifyingProduct => qualifyingProduct.id === productId
          );

          if (!productMatch) return false;

          if (gift.qualifyingVariants && gift.qualifyingVariants.length > 0) {
            return gift.qualifyingVariants.some(
                qualifyingVariant => qualifyingVariant.id === variantId
            );
          }

          return true;
        });

        matchingGifts.forEach(gift => {
          const existingIndex = state.qualifiedProductGifts.findIndex(
              qualifiedGift => qualifiedGift.id === gift.id
          );

          if (existingIndex >= 0) {
            state.qualifiedProductGifts[existingIndex].qualifiedQuantity += quantity;
          } else {
            state.qualifiedProductGifts.push({
              ...gift,
              qualifiedQuantity: quantity
            });
          }
        });
      });
    }
  },
  extraReducers: (builder) => {
    builder
        .addCase(fetchProductGifts.pending, (state) => {
          state.loading = true;
        })
        .addCase(fetchProductGifts.fulfilled, (state, action) => {
          state.loading = false;
          state.gifts = action.payload;
        })
        .addCase(fetchProductGifts.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        })
        .addCase(updateCart, (state, action) => {
          const cartItems = action.payload.lines.nodes;

          state.qualifiedProductGifts = [];

          cartItems.forEach(item => {
            const productId = item.merchandise.product.id;
            const variantId = item.merchandise.id;
            const quantity = item.quantity;

            const matchingGifts = state.gifts.filter(gift => {
              const productMatch = gift.qualifyingProducts && gift.qualifyingProducts.some(
                  qualifyingProduct => qualifyingProduct.id === productId
              );

              if (!productMatch) return false;

              if (gift.qualifyingVariants && gift.qualifyingVariants.length > 0) {
                return gift.qualifyingVariants.some(
                    qualifyingVariant => qualifyingVariant.id === variantId
                );
              }

              return true;
            });

            matchingGifts.forEach(gift => {
              const existingIndex = state.qualifiedProductGifts.findIndex(
                  qualifiedGift => qualifiedGift.id === gift.id
              );

              if (existingIndex >= 0) {
                state.qualifiedProductGifts[existingIndex].qualifiedQuantity += quantity;
              } else {
                state.qualifiedProductGifts.push({
                  ...gift,
                  qualifiedQuantity: quantity
                });
              }
            });
          });
        })
  }
});

export const { updateProductGiftQualifications } = productGiftsSlice.actions;
export default productGiftsSlice.reducer;
