const ACTIVE = process.env.GATSBY_CLUB_ACTIVE;

export function getClubPrice(product) {
  if (ACTIVE === "club_us") {
    return product.variants[0]?.clubUS?.value ?? product.clubUS?.value;
  } else if (ACTIVE === "club_gb") {
    return product.variants[0]?.clubUK?.value ?? product.clubUK?.value;
  } else if (ACTIVE === "club_nz") {
    return product.variants[0]?.clubNZ?.value ?? product.clubNZ?.value;
  } else if (ACTIVE === "club_au") {
    return product.variants[0]?.clubAU?.value ?? product.clubAU?.value;
  } else if (ACTIVE === "club_pt") {
    return product.variants[0]?.clubPT?.value ?? product.clubPT?.value;
  }
}

export function getVariantClubPrice(variant) {
  if (ACTIVE === "club_us") {
    return variant.clubUS?.value;
  } else if (ACTIVE === "club_gb") {
    return variant.clubUK?.value;
  } else if (ACTIVE === "club_nz") {
    return variant.clubNZ?.value;
  } else if (ACTIVE === "club_au") {
    return variant.clubAU?.value;
  } else if (ACTIVE === "club_pt") {
    return variant.clubPT?.value;
  }
}
