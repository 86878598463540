import { createListenerMiddleware } from '@reduxjs/toolkit';
import {updateCart} from '../cart';
import {removeFreeGiftMetafields, setFreeGiftMetafield} from '../../lib/dynamicQueries/cart/updateMetafields';

export const productGiftsMiddleware = createListenerMiddleware();

productGiftsMiddleware.startListening({
  actionCreator: updateCart,
  effect: async (action, listenerApi) => {
    const state = listenerApi.getState();
    const cartId = action.payload.id;

    const { freeGiftTier } = state.cart;

    if (freeGiftTier?.isProductGift) {
      const { loading, qualifiedProductGifts } = state.productGifts;

      if (loading) {
        return;
      }

      const isStillQualified = qualifiedProductGifts.some(gift => gift.id === freeGiftTier.id);

      if (!isStillQualified) {
        try {
          await removeFreeGiftMetafields(cartId);
          // Dispatch clearSelectedGift to clear the gift from state
          listenerApi.dispatch({ type: 'cart/clearSelectedGift', payload: null });
        } catch (e) {
          console.error('Error removing free gift after qualifying product removed:', e);
        }
        return;
      }

      const qualifiedProductGift = qualifiedProductGifts.find(gift => gift.id === freeGiftTier.id);
      if (freeGiftTier.qualifiedQuantity !== qualifiedProductGift.qualifiedQuantity) {
        await setFreeGiftMetafield(cartId, {
          ...freeGiftTier,
          qualifiedQuantity: qualifiedProductGift.qualifiedQuantity
        });
      }
    }
  }
});
