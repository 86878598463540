import {parseCurrency} from "./discountPrice";
import { IsBrowser } from "../utils/isBrowser";
import {getClubPrice} from "./clubPrice";

const ACTIVE_CURRENCY = process.env.GATSBY_LOCALE_CURRENCY;
const SITE_URL = process.env.GATSBY_PRIMARY_URL;

export const useDatalayer = () => {
    const viewProduct = (product, variant) => {
        if (!IsBrowser()) {
            return;
        }
        if (!window.dataLayer) {
            window.dataLayer = [];
        }
        const event = {
            event: "view_item",
            ecommerce: {
                items: [
                    mapProductToItem(product, variant)
                ]
            }
        };
        window.dataLayer.push(event);
    }

    const viewCollection = (collection) => {
        if (!IsBrowser()) {
            return;
        }
        if (!window.dataLayer) {
            window.dataLayer = [];
        }
        const event = {
            event: "view_items",
            ecommerce: {
                items: collection.products.filter((product) => product.variants.length > 0).map((product) => {
                    product.collections = [{
                        title: collection.title
                    }];
                    if (!product.variants.length) {
                        return false;
                    }
                    return mapProductToItem(product, product.variants[0]);
                })
            }
        };
        window.dataLayer.push(event);
    }

    const addToCart = (product, variant, quantity = 1) => {
        if (!IsBrowser()) {
            return;
        }
        if (!window.dataLayer) {
            window.dataLayer = [];
        }
        const event = {
            event: "add_to_cart",
            ecommerce: {
                items: [
                    mapProductToItem(product, variant, quantity)
                ]
            }
        };
        window.dataLayer.push(event);
    }

    const beginCheckout = (products) => {
        if (!IsBrowser()) {
            return;
        }
        if (!window.dataLayer) {
            window.dataLayer = [];
        }
        const event = {
            event: "begin_checkout",
            ecommerce: {
                items: products.map(({product, variant, quantity}) => {
                    return mapProductToItem(product, variant, quantity);
                })
            }
        };
        window.dataLayer.push(event);
    }

    const mapProductToItem = (product, variant, quantity = 1) => {
        const productId = product?.shopifyId?.replace("gid://shopify/Product/", '') ?? '';
        const variantId = variant?.shopifyId?.replace("gid://shopify/ProductVariant/", '') ?? '';
        const variantLabel = variant.selectedOptions.map((option) => {
            return option.value;
        }).join(" / ");
        const url = `${SITE_URL}/products/${product.handle}?variant=${variantId}`;

        let imageUrl = '';
        if (Array.isArray(variant.media) && variant.media.length > 0) {
            imageUrl = variant.media[0]?.preview?.image?.originalSrc;
        } else if (variant.image?.url) {
            imageUrl = variant.image.url;
        } else if (Array.isArray(product.media) && product.media.length > 0) {
            imageUrl = product.media[0]?.preview?.image?.originalSrc;
        }
        const event = {
            item_name: `${product.shopifyTitle ?? product.title} - ${variantLabel}`,
            item_id: `shopify_NZ_${productId}_${variantId}`,
            product_id: productId,
            variant_id: variantId,
            item_brand: product.vendor,
            item_category: product.collections?.length ? product.collections[0].title : null,
            quantity: quantity,
            currency: ACTIVE_CURRENCY,
            image_url: imageUrl,
            url: url,
            sku: variant.sku,
        };

        const clubPrice = getClubPrice(product) ?? null;
        if (clubPrice) {
            event.price = parseCurrency(clubPrice);
        } else {
            if (variant.price !== null && typeof variant.price === "object" && 'amount' in variant.price) {
                event.price = variant.price.amount;
            } else {
                event.price = variant.price;
            }
        }
        return event;
    }

    return {
        viewProduct,
        addToCart,
        viewCollection,
        beginCheckout
    };
};
