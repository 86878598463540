import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cartId: "",
  draftOrderId: "",
  checkoutUrl: "",
  items: [],
  discount: 0,
  deposit: 0,
  balance: 0,
  discountCode: null,
  estimatedCost: null,
  protectionVariantId: "gid://shopify/ProductVariant/39804853813347",
  giftsWithPurchase: [],
  bundles: [],
  freeGiftTier: null,
  availableProductGifts: [],
  selectedGift: null,
};

const CART_ID = `bushbuck-cartId-${process.env.GATSBY_LOCALE_COUNTRY}`;
const DRAFT_ORDER_ID = `bushbuck-draftorderid-${process.env.GATSBY_LOCALE_COUNTRY}`;

export function getEstimateCost(items, bundles = []) {
  const cartEstimate = items.reduce(
      (totalCost, item) =>
          item.merchandise.compareAtPrice
              ? totalCost +
              Number(item.merchandise.compareAtPrice.amount) * item.quantity
              : totalCost + Number(item.merchandise.price.amount) * item.quantity,
      0
  );

  const bundleEstimate = bundles.reduce((totalCost, productBundle) => {
    if (productBundle.bundle.compareAtPrice) {
      return totalCost + parseFloat(productBundle.bundle.compareAtPrice) * productBundle.quantity;
    }
    const selectedVariants = productBundle.selectedVariants ?? productBundle.bundle.selectedVariants ?? [];
    const comparePrice = selectedVariants.reduce((bundleCost, variant) => {
      const price = variant.compareAtPrice?.amount ?? variant.price.amount;

      return bundleCost + parseFloat(price);
    }, 0);
    return totalCost + comparePrice * productBundle.quantity;
  }, 0);

  return bundleEstimate + cartEstimate;
}

export function getEstimatedCostForPhysicalItems(items, bundles = []) {
  const cartEstimate = items.reduce(
      (totalCost, item) => {
        if (item.merchandise.requiresShipping !== true) {
          return totalCost;
        }
        return item.merchandise.clubPrice
            ? totalCost +
            Number(item.merchandise.clubPrice) * item.quantity
            : totalCost + Number(item.merchandise.price.amount) * item.quantity;
      },
      0
  );

  const bundleEstimate = bundles.reduce((totalCost, productBundle) => {
    if (productBundle.bundle.compareAtPrice) {
      return totalCost + parseFloat(productBundle.bundle.compareAtPrice) * productBundle.quantity;
    }
    const selectedVariants = productBundle.selectedVariants ?? productBundle.bundle.selectedVariants ?? [];
    const comparePrice = selectedVariants.reduce((bundleCost, variant) => {
      const price = variant.compareAtPrice?.amount ?? variant.price.amount;

      return bundleCost + parseFloat(price);
    }, 0);
    return totalCost + comparePrice * productBundle.quantity;
  }, 0);

  return bundleEstimate + cartEstimate;
}

const isQualifyingProductInCart = (freeGiftTier, cartItems, qualifiedProductGifts) => {
  if (!freeGiftTier?.isProductGift) {
    return true;
  }

  const matchingGift = qualifiedProductGifts.find(gift => gift.id === freeGiftTier.id);

  return !!matchingGift;
};

export function calculateAvailableProductGifts(items, productGifts) {
  if (!items || !productGifts) return [];

  const productQuantities = {};

  items.forEach(item => {
    const productId = item.merchandise.product.id;
    if (!productQuantities[productId]) {
      productQuantities[productId] = 0;
    }
    productQuantities[productId] += item.quantity;
  });

  const availableGifts = productGifts.map(gift => {
    const sourceProductId = gift.sourceProduct.id;
    const qualifyingQuantity = productQuantities[sourceProductId] || 0;

    return {
      ...gift,
      qualifyingQuantity,
      isAvailable: qualifyingQuantity > 0
    };
  }).filter(gift => gift.isAvailable);

  return availableGifts;
}

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addItem: (state, action) => {
      localStorage.setItem(CART_ID, action.payload.id);
      state.items.push(action.payload);
    },
    updateCart: (state, action) => {
      localStorage.setItem(CART_ID, action.payload.id);
      state.cartId = action.payload.id;
      state.checkoutUrl = action.payload.checkoutUrl;
      state.deposit = 0;
      state.balance = 0;
      state.items = action.payload.lines.nodes.map((node) => {
        if (node.preorder) {
          const preorder = JSON.parse(node.preorder.value);
          let depositAmount = 0;
          if (preorder.deposit_percent !== null) {
            depositAmount = (parseFloat(node.merchandise.price.amount) * preorder.deposit_percent) / 100;
          } else if (preorder.deposit_amount !== null) {
            depositAmount = preorder.deposit_amount * node.quantity;
          }
          state.deposit += depositAmount;
          state.balance += (parseFloat(node.merchandise.price.amount * node.quantity) - depositAmount);
          return {
            ...node,
            preorder,
          };
        }
        return node;
      });

      const freeGiftTier = action.payload.freeGiftTier ? JSON.parse(action.payload.freeGiftTier.value) : null;
      state.freeGiftTier = freeGiftTier;

      const selectedGift = action.payload.selectedGift ? JSON.parse(action.payload.selectedGift.value) : null;
      if (selectedGift) {
        state.selectedGift = selectedGift;
      }

      const productGifts = action.payload.productGifts ? JSON.parse(action.payload.productGifts.value) : [];
      if (productGifts.length > 0) {
        state.availableProductGifts = calculateAvailableProductGifts(state.items, productGifts);
      }

      const productBundles = action.payload.productBundles ? JSON.parse(action.payload.productBundles.value) : [];
      state.bundles = productBundles;

      const cost = getEstimateCost(action.payload.lines.nodes, productBundles);

      state.estimatedCost = Number(cost).toFixed(2);
      let discountAmount = 0;
      action.payload.lines.nodes.map((line) => {
        if (
            line.merchandise.compareAtPrice &&
            line.merchandise.price.amount !==
            line.merchandise.compareAtPrice?.amount
        ) {
          discountAmount +=
              (line.merchandise.compareAtPrice.amount -
                  line.merchandise.price.amount) *
              line.quantity;
        }
      });
      state.discount = discountAmount;
    },

    getCart: (state, action) => {
      state.cartId = localStorage.getItem(CART_ID);
      state.draftOrderId = localStorage.getItem(DRAFT_ORDER_ID);
    },

    removeCart: (state, action) => {
      localStorage.removeItem(CART_ID);
      state = {...initialState};
    },

    setDraftOrderId: (state, action) => {
      localStorage.setItem(DRAFT_ORDER_ID, action.payload.id);
    },

    setDiscountCode: (state, action) => {
      state.discountCode = action.payload;
    },

    setFreeGiftTier: (state, action) => {
      state.freeGiftTier = action.payload;

      if (action.payload) {
        state.selectedGift = {
          type: 'tier',
          id: action.payload.id,
          gift: action.payload
        };
      } else if (state.selectedGift && state.selectedGift.type === 'tier') {
        state.selectedGift = null;
      }
    },

    setAvailableProductGifts: (state, action) => {
      state.availableProductGifts = action.payload;
    },

    setSelectedProductGift: (state, action) => {
      state.selectedGift = {
        type: 'product',
        id: action.payload.id,
        gift: action.payload,
        sourceProductId: action.payload.sourceProduct.id,
        giftProductId: action.payload.giftProduct.id,
        giftVariantId: action.payload.giftVariant.id,
        qualifyingQuantity: action.payload.qualifyingQuantity || 1
      };

      state.freeGiftTier = null;
    },

    clearSelectedGift: (state) => {
      state.selectedGift = null;
      state.freeGiftTier = null;
    }
  },
});

export const {
  updateCart,
  addItem,
  getCart,
  removeCart,
  setDraftOrderId,
  setDiscountCode,
  setFreeGiftTier,
  setAvailableProductGifts,
  setSelectedProductGift,
  clearSelectedGift
} = cartSlice.actions;

export default cartSlice.reducer;
